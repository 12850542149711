import dayjs from 'dayjs';

export const pathBlocklist = [
  '/hubspot-content-utilization',
  '/passport-control',
  '/redirect',
  '/contact-lists-selection'
];

export const sanitizeParam = (param) => {
  // eslint-disable-next-line no-useless-escape
  const sanitizationRegex = /[^\w\s\.\:\/\?\&\@\_\-+]/gi; // whitelist

  return (param || '').replace(sanitizationRegex, '');
};

export const changeColorOpacity = (color, opacity) => {
  const newColor = color.split(',');
  newColor.splice(-1, 1, `${opacity})`);
  return newColor.join(',');
};

export const convertRemToPixels = (rem) =>
  rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export const isObjectsEqual = (oldParams, newParams) => {
  let areParamsTheSame = true;
  const oldParamsKeys = Object.keys(oldParams);
  const newParamsKeys = Object.keys(newParams);

  if (oldParamsKeys.length !== newParamsKeys.length) return false;

  oldParamsKeys.forEach((key) => {
    if (JSON.stringify(oldParams[key]) !== JSON.stringify(newParams[key])) {
      areParamsTheSame = false;
    }
  });
  return areParamsTheSame;
};

export const isArraysEqual = (firstArray, secondArray) => {
  const maxLength = Math.max(firstArray, length, secondArray.length);

  for (let i = 0; i < maxLength; i++) {
    if (firstArray[i] !== secondArray[i]) {
      return false;
    }
  }

  return true;
};

export const getDaySuffix = (date) => {
  switch (date) {
    case 1:
    case 21:
    case 31:
      return 'st';
    case 2:
    case 22:
      return 'nd';
    case 3:
    case 23:
      return 'rd';
    default:
      return 'th';
  }
};

export const mapLabelsForChart = (timeSeries) => {
  const daysAbbreviation = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thur',
    'Fri',
    'Sat',
    'Sun',
  ];

  if (!timeSeries[0]) return [];

  const { resolution } = timeSeries[0];
  const timestampsArray = timeSeries.map((item) => ({
    timestamp: item.timestamp,
  }));

  const labels = [];

  for (const item of timestampsArray) {
    const date = new Date(item.timestamp);

    if (resolution === 'DAILY') {
      labels.push(daysAbbreviation[date.getDay()]);
    } else if (resolution === 'WEEKLY') {
      // Get the start and end dates of the week
      const startOfWeek = new Date(date);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);

      const startDaySuffix = getDaySuffix(startOfWeek.getDate());

      labels.push(
        `${startOfWeek.getDate()}${startDaySuffix} ${startOfWeek.toLocaleString(
          'default',
          { month: 'short' },
        )}`,
      );
    } else {
      // Just display the month and year for monthly resolution
      labels.push(
        `${date.toLocaleString('default', {
          month: 'short',
        })} ${date.getFullYear()}`,
      );
    }
  }

  return labels;
};

export const getFromEngagmentRange = (engagment) => {
  const RANGE_COLORS = {
    Poor: '#F05858',
    Low: '#F08F58',
    Ok: '#F6C25D',
    Good: 'B0C483',
    Great: '#84B59F',
  };

  switch (true) {
    case engagment <= 10:
      return {
        title: 'Poor',
        color: RANGE_COLORS['Poor'],
      };
    case engagment <= 20:
      return {
        title: 'Low',
        color: RANGE_COLORS['Low'],
      };
    case engagment <= 50:
      return {
        title: 'Ok',
        color: RANGE_COLORS['Ok'],
      };
    case engagment <= 65:
      return {
        title: 'Good',
        color: RANGE_COLORS['Good'],
      };
    case engagment > 65:
      return {
        title: 'Great',
        color: RANGE_COLORS['Great'],
      };
  }
};

export const serializeParams = (params) => {
  const serializedParams = { ...params };

  Object.keys(serializedParams).forEach((key) => {
    if (typeof serializedParams[key] === 'number') return;
    if (!serializedParams[key].length) {
      delete serializedParams[key];
    }
  });
  return serializedParams;
};

export const cartesian2Polar = (x, y) => {
  const distance = Math.sqrt(x * x + y * y);
  const radians = Math.atan2(y, x); //This takes y first
  const polarCoor = { distance, radians };
  return polarCoor;
};

export const validateURLParams = (queryParams) => {
  Object.keys(queryParams).forEach((key) => {
    if (Array.isArray(queryParams[key])) {
      const value = queryParams[key].join(`&${key}=`);
      queryParams[key] = value;
    }
  });

  return queryParams;
};

export const getCountryAdjustedDate = (iso) => {
  const localTime = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (localTime.includes('America')) {
    return dayjs(iso).format('MM/DD/YYYY');
  }
  return dayjs(iso).format('DD/MM/YYYY');
};

export const mapTitlesFromURL = (pathname) => {
  if (pathname.split('?')[0] === '/') return 'Radar';

  const titlesForURL = {
    'quick-start': 'Quick Start',
    contacts: 'Contacts',
    competitors: 'Competitors',
    'competitors/content': 'Competitor content',
    topics: 'Topics',
    '/topics/detail': 'Topic details',
    contents: 'Content',
    'contents/detail': 'Content details',
  };

  let matchedTitle = null;

  Object.keys(titlesForURL).forEach((key) => {
    const pathnameWithoutParams = pathname.split('?')[0];
    if (pathnameWithoutParams.includes(key)) {
      matchedTitle = titlesForURL[key];
    }
  });

  return matchedTitle;
};

export const getBackUrl = (urlState, fallbackUrl) => {
  if (urlState?.prevUrl?.length) {
    return urlState?.prevUrl?.[urlState?.prevUrl?.length - 1];
  }
  return fallbackUrl;
};

export const capitalizeFirstLetter = (string) => {
  if(!string) return
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const queryStringBuilder = (email, orgName, orgDomain, flow) =>{
  const encodedEmail = encodeURIComponent(email);
  const encodedOrgName = encodeURIComponent(orgName);
  const encodedOrgDomain = encodeURIComponent(orgDomain);
  const encodedFlow = encodeURIComponent(flow);
  const queryString = `?email=${encodedEmail}&orgName=${encodedOrgName}&orgDomain=${encodedOrgDomain}&flow=${encodedFlow}`;
  return queryString;
}
