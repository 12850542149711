import { Box, MultiSelect, Stack, rem } from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import ContactListsMultiSelectItem from './ContactListsMultiSelectItem';
import CustomText from '../../../Typography/CustomText/CustomText';
import { convertRemToPixels } from '../../../../utils';

import logger from '../../../../utils/logger';

const CONTACT_LIST_OFFSET = convertRemToPixels(2.25);

function ContactListsMultiSelect({
  data,
  onChange,
  value,
  urlParams,
  showError,
  disabled,
  bottomOffset = 0,
}) {
  const [dropdownHeight, setDropdownHeight] = useState(0);

  const validatedData = useMemo(() => {
    if (!data) return [];

    return data.map((el) => ({
      value: JSON.stringify({ ...el, listId: `${el?.listId}` }),
      label: el?.name,
    }));
  }, [data]);

  const validatedValue = useMemo(() => {
    if (!value) return [];

    return value.map((el) => JSON.stringify(el));
  }, [value, urlParams]);

  const allowedMaxSelectedValues = useMemo(() => {
    if (urlParams?.listIds?.length) {
      if (value?.length === 0) {
        return -1;
      }
      return Math.min(urlParams.listIds.length, value?.length);
    }
  }, [urlParams, value]);

  useEffect(() => {
    setTimeout(() => {
      const dropdownElement = document
        .querySelector('.mantine-MultiSelect-dropdown')
        ?.getBoundingClientRect();
      logger.debug(dropdownElement);
      const currentDropdownHeight = dropdownElement?.height;
      setDropdownHeight(
        Math.max(currentDropdownHeight - CONTACT_LIST_OFFSET, 0) + bottomOffset,
      );
    }, 1000);
  }, []);

  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        <Box
          mb={rem(dropdownHeight)}
          sx={{ transition: '0.1s' }}
        >
          <MultiSelect
            label="Your contact lists"
            placeholder="Search your lists"
            initiallyOpened={false}
            searchable
            value={validatedValue}
            data={validatedData}
            disabled={disabled}
            transitionProps={{
              duration: 100,
              transition: 'scale',
              timingFunction: 'ease',
            }}
            dropdownPosition="bottom"
            onDropdownOpen={() => {
              setTimeout(() => {
                const dropdownElement = document
                  .querySelector('.mantine-MultiSelect-dropdown')
                  ?.getBoundingClientRect();
                logger.debug(dropdownElement);
                const currentDropdownHeight = dropdownElement?.height;
                setDropdownHeight(
                  Math.max(currentDropdownHeight - CONTACT_LIST_OFFSET, 0) + bottomOffset,
                );
              }, 500);
            }}
            onChange={(value) => {
              onChange(value.map((el) => JSON.parse(el)));
            }}
            onDropdownClose={() => {
              setTimeout(() => {
                setDropdownHeight(0);
              }, 200);
            }}
            itemComponent={ContactListsMultiSelectItem}
            maxSelectedValues={allowedMaxSelectedValues}
            nothingFound="Nothing found..."
            styles={(thema) => ({
              item: {
                ':hover': {
                  backgroundColor: 'rgb(241, 243, 245)',
                },
              },
              value: {
                backgroundColor: urlParams?.listIds?.length
                  ? '#ED7470'
                  : 'rgb(241, 243, 245)',
              },
              dropdown: {
                marginTop: rem(2),
              },
              input: {
                maxHeight: rem(150),
                overflow: 'auto',

                '::-webkit-scrollbar': {
                  width: '2px',
                },

                border: showError && `1px solid ${thema.colors.red[0]}`,
              },
            })}
          />
        </Box>
        <>
          {showError && (
            <CustomText
              variant="body2"
              sx={(theme) => ({ color: theme.colors.red[0] })}
            >
              {showError}
            </CustomText>
          )}
        </>
      </Stack>
    </Stack>
  );
}

export default ContactListsMultiSelect;
