// hooks/useHubspotFlow.js
import { useEffect } from 'react';
import useIntegrations from '../../../hooks/useIntegrations';
import useRadarApi from '../../../hooks/useRadarApi';
import { passportControlConfig } from '../../../config';

const { baseWWWUrl, delayBeforeRedirection, testFlightRedirectPath, contentUtilizationRedirectPath } = passportControlConfig;

const useHubspotFlow = ({ flow, successfullyConnected }) => {
  const { hubspotDetails } = useIntegrations();
  const { getHubspotAuthURL } = useRadarApi();

  const handleHubspotConnection = async () => {
    const url = await getHubspotAuthURL({
      redirectPath: `contact-lists-selection?flow=${flow}`,
      metadata: JSON.stringify({ flow }),
    });

    window.location.href = url;
  };

  useEffect(() => {
    if (successfullyConnected && hubspotDetails?.id) {
      const nextStepsPath =
        flow === 'testFlight'
          ? testFlightRedirectPath
          : contentUtilizationRedirectPath;
      setTimeout(() => {
        window.location.href = `${baseWWWUrl}${nextStepsPath}`;
      }, delayBeforeRedirection);
    }
  }, [successfullyConnected, hubspotDetails, flow]);

  return { handleHubspotConnection, hubspotDetails };
};

export default useHubspotFlow;
