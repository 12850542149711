// PassportControl.jsx
import React, { useMemo } from 'react';
import { Box, Center, Stack, rem, Group, UnstyledButton } from '@mantine/core';
import { ReactComponent as LogoIcon } from '../../assets/icons/ATC-logo-colored.svg';
import CustomText from '../../components/Typography/CustomText/CustomText';
import CustomTitle from '../../components/Typography/CustomTitle/CustomTitle';
import MainButton from '../../components/buttons/buttons';
import { ReactComponent as ArrangeSquareIcon } from '../../assets/icons/arrange-square-icon.svg';
import Snackbar from '../../components/Snackbar';
import { useStyle } from '../../features/passportControlLanding/styles';
import useQueryParams from './hooks/useQueryParams';
import useIntegrations from '../../hooks/useIntegrations';
import CustomCheckbox from '../../components/Checkbox';
import useContactLists from '../../components/settings/IntegrationsTab/hooks/useContactLists';
import useRadarApi from '../../hooks/useRadarApi';
import ContactListsMultiSelect from '../../components/settings/IntegrationsTab/components/ContactListsMultiSelect';
import { useNavigate } from 'react-router-dom';
import logger from '../../utils/logger';
import OrgSelectionMenu from '../../components/header/OrgSelectionMenu/OrgSelectionMenu';

const FLOW_OPTIONS = {
  TEST_FLIGHT: 'testFlight',
  CONTENT_UTILIZATION_CAMPAIGN: 'contentUtilizationCampaign',
};

const ContactListsSelectionPage = () => {
  const { classes } = useStyle();
  const { hubspotDetails } = useIntegrations();
  const { getHubspotContactLists } = useRadarApi();
  const navigate = useNavigate();
  const params = useQueryParams();
  const { flow } = params;

  const {
    contactLists: availableHubspotLists,
    selectedLists: selectedHubspotLists,
    setSelectedLists: setSelectedHubspotLists,
    selectAll: hubspotSyncAllContacts,
    setSelectAll: setHubspotSyncAllContacts,
    patchContactLists: patchHubspotContactLists,
    showError: showHubspotError,
  } = useContactLists(
    hubspotDetails?.contactLists,
    getHubspotContactLists,
    hubspotDetails?.syncAllContacts,
  );

  const integrationExists = useMemo(() => {
    //check if integration object has defined connected field
    return typeof hubspotDetails?.connected === 'boolean';
  }, [hubspotDetails]);

  const updateHubspotContactLists = () => {
    const onSuccessfullyUpdate = () => {
      navigate(`/redirect?flow=${flow}`, { replace: true });
    };

    try {
      patchHubspotContactLists(hubspotDetails?.id, null, onSuccessfullyUpdate);
    } catch (error) {
      logger.error('Error updating contact lists', error);
      return;
    }
  };

  return (
    <>
      <Center h="100vh">
        <Box sx={{ position: 'absolute', top: rem(32), right: rem(32) }}>
          <OrgSelectionMenu>
            <CustomText sx={{ fontWeight: '700' }}>Switch Account:</CustomText>
          </OrgSelectionMenu>
        </Box>
        <Stack spacing={32} align="center">
          <Stack spacing={16} align="center" w={rem(510)}>
            <LogoIcon
              className="logo-icon"
              style={{ width: rem(217), height: rem(72) }}
            />
            <CustomTitle order={0} sx={{ textAlign: 'center' }}>
              Select Your HubSpot <br />
              Contact Lists
            </CustomTitle>
            <Box className={classes.TimelineWrapper}>
              <CustomText variant="h2" sx={{ textAlign: 'center' }}>
                Choose one or more contact lists. These lists will help us
                customize your experience based on the contacts you want to
                engage with. Select the lists that best fit your needs, and
                you're ready to move forward!
              </CustomText>
            </Box>
          </Stack>
          <Stack w="100%" align="end">
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                '& > div': {
                  width: '100%',
                },
              }}
            >
              <ContactListsMultiSelect
                onChange={setSelectedHubspotLists}
                data={availableHubspotLists || []}
                value={selectedHubspotLists}
                showError={showHubspotError && 'Select at least one list'}
                disabled={hubspotSyncAllContacts}
                bottomOffset={32} // bottom padding in px when dropdown is open
              />
            </Box>
            <UnstyledButton
              onClick={() =>
                setHubspotSyncAllContacts((prevState) => !prevState)
              }
            >
              <Group>
                <CustomText variant="body2">Import all contacts:</CustomText>
                <CustomCheckbox checked={hubspotSyncAllContacts} />
              </Group>
            </UnstyledButton>
          </Stack>
          <Box
            w="100%"
            sx={{
              '& button': {
                width: '100%',
              },
            }}
          >
            <MainButton
              onClick={updateHubspotContactLists}
              icon={<ArrangeSquareIcon />}
              sx={{ height: rem(48) }}
              disabled={
                (!hubspotSyncAllContacts && !selectedHubspotLists?.length) ||
                !integrationExists
              }
            >
              <CustomText variant="body1">Save</CustomText>
            </MainButton>
          </Box>
        </Stack>
      </Center>
      <Snackbar />
    </>
  );
};

export default ContactListsSelectionPage;
