import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import PageLoader from '../components/PageLoader';
import usePostLoginRedirect from '../hooks/usePostLoginRedirect';

const AuthenticationGuard = ({ component: Component, children }) => {
  usePostLoginRedirect();

  return Component ? <Component>{children}</Component> : <>{children}</>;
};

export default withAuthenticationRequired(AuthenticationGuard, {
  onRedirecting: () => (
    <div className="page-layout">
      <PageLoader />
    </div>
  ),
});
