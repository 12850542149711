import { useEffect } from 'react';
import './LandingPage.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

function LandingPage() {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    // Get the current full path (path + query params)
    const currentPath = location.pathname + location.search;

    // Redirect URL logic, defaulting to currentPath for preservation
    const redirectUrl =
      localStorage.getItem('skipPreviewPage') === 'true'
        ? currentPath
        : '/quick-start' + location.search;

    // Trigger login with the redirect URL stored in appState
    loginWithRedirect({
      appState: {
        returnTo: redirectUrl,
      },
    });
  }, [loginWithRedirect, location]);

  return <></>;
}

export default LandingPage;
