import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const usePostLoginRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    // Capture the current path and query parameters
    const currentPathWithQuery = `${location.pathname}${location.search}`;

    // If not authenticated, store the path for later
    if (!isAuthenticated) {
      localStorage.setItem('authRedirect', currentPathWithQuery);
    } else {
      // If authenticated, redirect to the stored path
      const redirectUrl = localStorage.getItem('authRedirect');
      if (redirectUrl) {
        navigate(redirectUrl, { replace: true }); // Navigate to the stored URL
        localStorage.removeItem('authRedirect'); // Clean up after redirect
      }
    }
  }, [isAuthenticated, navigate, location]);
};

export default usePostLoginRedirect;
