import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './LogoutPage.css';

function LogoutPage() {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({
      logoutParams: {
        returnTo: 'https://www.airtrafficcontrol.io',
      },
    });
  }, [logout]);

  return null;
}

export default LogoutPage;
